import * as bootstrap from 'bootstrap';
import feather from 'feather-icons';
import { createRoot } from 'react-dom/client';
import React from 'react';
import {
  PaginationState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  createColumnHelper,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'
import { BuybackRow } from '../model/buyback';
import Formatter from '../../common/formatter';

function App({ data_key }) {
  const rerender = React.useReducer(() => ({}), {})[1]

  React.useEffect(() => {
    feather.replace(); // Initialize Feather Icons
  }, []);

  const columnHelper = createColumnHelper<BuybackRow>()
  const columns = React.useMemo<ColumnDef<BuybackRow>[]>(() => [
      columnHelper.accessor(row => row.issuer_trading_symbol.toUpperCase(), {
        id: 'issuer_trading_symbol',
        header: 'Ticker',
        cell: (cell) => <a href={cell.row.original.issuer_url}>{cell.getValue()}</a>,
      }),
      columnHelper.accessor(row => row.issuer_name, {
        id: 'issuer_name',
        header: 'Company',
        cell: (cell) => <a href={cell.row.original.issuer_url}>{cell.getValue()}</a>,
      }),

      columnHelper.accessor(row => row.repurchase_count, {
        id: 'repurchase_count',
        header: '# Buybacks',
        cell: (cell) => cell.getValue(),
      }),

      columnHelper.accessor(row => row.total_amount, {
        id: 'total_amount',
        header: () => (
          <>
            Avg. Value Purchased
            <br />
            <small style={{ color: 'rgb(224 224 224)' }}>($ in 000s)</small>
          </>
        ),
        cell: (cell) => Formatter.price(cell.getValue() / 1_000, true),
      }),
      columnHelper.accessor(row => row.price, {
        id: 'price',
        header: 'Avg. Purchase Price',
        cell: (cell) => Formatter.price(cell.getValue()),
      }),
      columnHelper.accessor(row => row.qty, {
        id: 'qty',
        header: 'Avg. Quantity Purchased',
        cell: (cell) => Formatter.owned(cell.getValue()),
      }),

      columnHelper.accessor(row => row.total_return, {
        id: 'total_return',
        header: 'Total Return to Present Day',
        cell: (cell) => Formatter.change(cell.getValue() * 100, 1),
      }),
      columnHelper.accessor(row => row.excess_return, {
        id: 'excess_return',
        header: 'Total Excess Return to Present Day',
        cell: (cell) => Formatter.change(cell.getValue() * 100, 1),
      }),
    ], []
  )

  const output = JSON.parse(document.getElementById('buyback_data')!.getAttribute('data')!).data;
  const [data, setData] = React.useState(() => output[data_key]);
  const refreshData = () => setData(() => output[data_key]);

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  })

  const [globalFilter, setGlobalFilter] = React.useState('')

  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
      globalFilter,
    },
    // globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: false,
  })

  React.useEffect(() => {
    const eventListener = (event) => {
      setGlobalFilter(event.detail.value);
    };

    // Add the event listener when the component mounts
    window.addEventListener('search', eventListener);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('search', eventListener);
    };
  }, []);

  return (
    <>
      <div className="block max-w-full overflow-x-scroll overflow-y-hidden table-responsive table-card">
        <div className="h-2" />
        <table className="table table-hover w-full" style={{marginBottom: 0}}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} colSpan={header.colSpan} style={{background: '#1864EF'}} className="text-white">
                      {(
                        header.isPlaceholder ? null : (
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        )
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default () => {
  createRoot(document.getElementById('app_best')).render(
    <React.StrictMode>
      <App data_key={'best'} />
    </React.StrictMode>
  )
  createRoot(document.getElementById('app_worst')).render(
    <React.StrictMode>
      <App data_key={'worst'} />
    </React.StrictMode>
  )
}
